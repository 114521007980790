<template>
  <div class="content">
    <app-cells position="between">
      <h1 class="title title--big title--theme">{{ contracts_info.name }}</h1>
      <app-cells :indent="false">
        <app-button @click="onEdit(contracts_info.id)" size="small">Редактировать</app-button>
        <app-button @click="onDeleteItem(contracts_info.id)" theme="delete" size="small">Удалить</app-button>
      </app-cells>
    </app-cells>
    <app-grid col="1" items="2">
      <template #item-1>
        <div class="view-cols">
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>ID</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.id }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Наименование объекта закупки</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.name }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Начальная (максимальная) цена контракта</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.main_price }} &#8381;
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Город</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.city && contracts_info.city.name }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Подразделение</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.department && contracts_info.department.name }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Исполнитель</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.executor && contracts_info.executor.full_name }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Планируемый срок заключения договора / оказания услуги / поставки товара</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.date_end }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Тип размещения (аукцион, электронный магазин, прямой договор)</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.type_allocation && contracts_info.type_allocation.name }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Этап осуществления закупки</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.stage && contracts_info.stage.name }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.date_documents_ur" class="view-cols__row">
            <div class="view-cols__part">
              <b>Срок передачи документов юристу</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.date_documents_ur }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.date_on_buy" class="view-cols__row">
            <div class="view-cols__part">
              <b>Срок размещения закупки</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.date_on_buy }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.date_end_req" class="view-cols__row">
            <div class="view-cols__part">
              <b>Дата и время окончания приема заявок</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.date_end_req }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.req_count" class="view-cols__row">
            <div class="view-cols__part">
              <b>Количество заявок</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.req_count }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Цена договора</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.summary }} &#8381;
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Наименование поставщика (подрядчика, исполнителя)</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.supplier && contracts_info.supplier.name }}
          <span v-if="contracts_info.supplier && contracts_info.supplier.inn">({{ contracts_info.supplier.inn }})</span>
        </span>
            </div>
          </div>
          <div v-if="contracts_info.date_provision_service" class="view-cols__row">
            <div class="view-cols__part">
              <b>Срок оказания услуг (поставки товара, выполнения работ)</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.date_provision_service }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.date_sign_product" class="view-cols__row">
            <div class="view-cols__part">
              <b>Дата выставление документов  (товарная накладная, акт)</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.date_sign_product }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Способ обеспечения исполнения контракта (банковская гарантия, денежные средства)</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.enforcement_method }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.enforcement_amount_contract" class="view-cols__row">
            <div class="view-cols__part">
              <b>Размер обеспечения исполнения контракта</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.enforcement_amount_contract }} &#8381;
        </span>
            </div>
          </div>
          <div v-if="contracts_info.enforcement_date_return" class="view-cols__row">
            <div class="view-cols__part">
              <b>Дата возврата обеспечения исполнения контракта</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.enforcement_date_return }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Способ обеспечения исполнения гарантийных обязательств (банковская гарантия, денежные средства)</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.enforcement_way && contracts_info.enforcement_way.name }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.enforcement_amount_security" class="view-cols__row">
            <div class="view-cols__part">
              <b>Размер обеспечения исполнения гарантийных обязательств</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.enforcement_amount_security }} &#8381;
        </span>
            </div>
          </div>
          <div v-if="contracts_info.enforcement_date_security" class="view-cols__row">
            <div class="view-cols__part">
              <b>Срок предоставления гарантийных обязательств</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.enforcement_date_security }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.enforcement_date_return_security" class="view-cols__row">
            <div class="view-cols__part">
              <b>Дата возврата обеспечения исполнения гарантийных обязательств</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.enforcement_date_return_security }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.due_date" class="view-cols__row">
            <div class="view-cols__part">
              <b>Срок оплаты</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.due_date }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Категория</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.category && contracts_info.category.name }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Подкатегория</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.sub_category && contracts_info.sub_category.name }}
        </span>
            </div>
          </div>
          <div class="view-cols__row">
            <div class="view-cols__part">
              <b>Требует продления?</b>
            </div>
            <div class="view-cols__part">
        <span>
          {{ contracts_info.need_renewal ? 'Да' : 'Нет' }}
        </span>
            </div>
          </div>
          <div v-if="contracts_info.docs && contracts_info.docs.length" class="view-cols__row">
            <div class="view-cols__part">
              <b>Вложения</b>
            </div>
            <div class="view-cols__part">
              <a
                v-for="file in contracts_info.docs"
                :key="file.id"
                :href="file.file"
                target="_blank"
              >
                {{ file.file_name }}
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #item-2>
        <h2 class="title title--indent">Комментарии</h2>
        <app-comment-head
          @update-limiter="onUpdateLimiter"
          :count="comments.length"
          :page="pagination.page"
          :all="pagination.count"
        />
        <app-comments :comments="comments" />
        <app-cells position="start">
          <paginate
            v-if="pagination.pages > 1"
            v-model="pagination.page"
            :page-count="pagination.pages"
            :clickHandler="onClickPagination"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination pagination--noindent'"
            :page-class="'pagination__item'"
            :page-link-class="'pagination__link'"
            :prev-class="'pagination__prev'"
            :next-class="'pagination__next'"
            :next-link-class="'pagination__link-next'"
            :prev-link-class="'pagination__link-prev'"
          />
        </app-cells>
        <form @submit.prevent="onCheckForm">
          <app-form-group>
            <app-textarea
              v-model="comment_form.comment"
              id="comment"
              :error="$v.comment_form.comment.$error"
              placeholder="Добавить комментарий"
            >
            </app-textarea>
            <template #error>
              <div v-if="$v.comment_form.comment.$dirty && !$v.comment_form.comment.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group>
            <app-uploader
              v-model="comment_form.file"
            />
          </app-form-group>
          <app-cells>
            <app-button :disabled="$v.comment_form.$error" ref="submit">Отправить</app-button>
          </app-cells>
        </form>
      </template>
    </app-grid>
  </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators'

export default {
  name: 'ContractsView',
  data() {
    return {
      contracts_info: {},
      comment_form: {},
      comments: [],
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      limit: 5,
    }
  },
  validations: {
    comment_form: {
      comment: {required},
    },
  },
  created() {
    this.$store.dispatch('contracts/GET_DATA', this.$route.params.id)
      .then(response => {
        this.contracts_info = response.data
      })
    this.fetchComments()
  },
  methods: {
    fetchComments(page = 1, page_size = 5) {
      this.$store.dispatch('contracts/GET_COMMENTS', {page, page_size, id: this.$route.params.id})
        .then(response => {
          this.comments = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onClickPagination(page) {
      this.fetchComments(page, this.limit)
    },
    onUpdateLimiter(data) {
      this.limit = data
      this.pagination.page = 1
      this.fetchComments(this.pagination.page, this.limit)
    },
    onCheckForm() {
      this.$v.comment_form.$touch()
      if (this.$v.comment_form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('contracts/POST_COMMENT', {id: this.$route.params.id, form: this.normalizeForm()})
        .then(() => {
          this.fetchComments(this.pagination.page)
          this.$refs.submit.preload = false
          this.$notify({
            type: 'success',
            text: 'Комментарий добавлен'
          })
          this.comment_form = {}
          this.$v.comment_form.$reset()
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    normalizeForm() {
      const { ...normalizedForm } = this.comment_form
      normalizedForm.contract = this.$route.params.id
      if (normalizedForm.file) normalizedForm.file = normalizedForm.file.id
      return normalizedForm
    },
    onEdit(id) {
      this.$router.push({name: 'contracts-edit', id })
    },
    onDeleteItem(id) {
      this.$store.dispatch('contracts/DELETE_DATA', id)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Контракт удален.'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  }
}
</script>
